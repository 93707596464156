import './App.css';
import React, { Component, useState } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import Profile from './component/profile';
import user from './assets/user.png';
import mM from './assets/MetaMask.png';
import Axios from 'axios';
import pic from './assets/pic.jpg';
import secretGif from './assets/secret_images.gif';
import $ from "jquery";
import arrow from './assets/up-arrrows.png';

let photoUploaded = 0;

/*
<div class="fileBtn">
	<input class="choosebtn" type="file" name="file" accept="image/png"
	id="photo" required="true" />
</div>
*/

/*$(function() {
	$(".approve").on("click", function() {
	  $(this).text("Submitting...");
	});
});*/

$(function () {
	$(document).on("click", ".approve", function () {
		$(this).text("Submitting...").css("opacity", "0.5");
	});
});

var cors = require('cors');

const website = () => {
	window.open("https://treepoets.com/");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let accountETH;
let mintAmountETH = 1;
let valueOfNFTsETH = 0;
let totalSupplyNFTETH;
let maxMintNFTs;
let onlyLeft;
let now;
let myTotalWL;
let tokenIDArray = [];
let tokenIDBool;
let x;
let successMessage;
let walletExist;
let points_table_array;
let votedAddresses = [];
let match = 0;
let eligible = 0;
let max_per_wallet;
let cost;
let tokenOfOwnerByIndex = [];
let max_per_walletETH;
let costETH;
let lastTokenMinted;
let myTokenIDs = [];
let selectedNFTID;
let polygonActivate = 0;
let ethActive = 1;
let NFTID;
let differenceT;
let timeMinted;

var date_ob;
var day;
var month;
var year;

var date;

var hours;
var minutes;
var seconds;

var dateTime;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "lastMintedNFTID",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "ID",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintedCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "mintedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "myMintedCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myMintedNFTs",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_timeGap",
				"type": "uint256"
			}
		],
		"name": "setTimeGap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "timeGap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

//const address = "0x61Ba98113FD775651152bf81B3aC6d5cFA1EB269";
const address = "0x47FCEbFd6ee90409D0180fBe4B9723F1f69e13da";

//const address = "0x55844a47e467e3B35D994b113Da8b4dad2A36394";

const ABIETH = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_cost",
				"type": "uint256"
			}
		],
		"name": "setMintRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const addressETH = "0xD53EEAF08dAebA65e99e5E925D2381410FF11276";

let contract;
let contractETH;
let wMintAmount = 1;
let totalWhitelistMinted;
let walletBalance;
let wStatus;
let publicSale;
let publicSaleETH;
let myNFTBalance;
let gasLimit = 285000;
let whitelistStatus;
let owner;
let ownerETH;
let tokenID;
let tokenURI;
let imageURI;
let plus;
let minus;
let points = 0;
let db_points;
let writersWallet;
var text;
let alreadySubmitted = false;
let initialScreen = 1;
let clickedWalletBtn = 0;
let publicMintMsg = "Public Mint";
let lastMintedNFTID;
let mintedTime = [];
let myTokenIDsWithTime = [];
let timeGap = 0;
let activePolygon;
let loading = 0;
let msgCode = 0;

function getWalletAddress() {

	// Get the input text value
	writersWallet = document.getElementById("writerWallet1").value;
}

function words() {

	// Get the input text value
	text = document
		.getElementById("w3review").value;

	// Initialize the word counter
	var numWords = 0;

	// Loop through the text
	// and count spaces in it
	for (var i = 0; i < text.length; i++) {
		var currentCharacter = text[i];

		// Check if the character is a space
		if (currentCharacter == " ") {
			numWords += 1;
		}
	}

	// Add 1 to make the count equal to
	// the number of words
	// (count of words = count of spaces + 1)
	numWords += 1;

	// Display it as output
	document.getElementById("show")
		.innerHTML = numWords;
}

function countWords(self) {
	try {

		var spaces = self.value.match("/\S+/g");
		var words = spaces ? spaces.length : 0;

		document.getElementById("words-counter").innerHTML = words + " words";

	} catch (err) {
		console.log(err);

	}

}

$(document).ready(() => {
	$('#photo').change(function () {
		const file = this.files[0];
		console.log(file);
		if (file) {
			let reader = new FileReader();
			reader.onload = function (event) {
				console.log(event.target.result);
				$('#imgPreview').attr('src', event.target.result);
			}
			reader.readAsDataURL(file);
		}
	});
});


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "",
		maxmint: '',
		stateNow: '',
		totalWhitelistSupply: '',
		myTotalWLNFTs: '',
		accountBalance: '',
		wlStatus: '',
		wlEvent: false,
		myNFTWallet: '',
		priceDisplay: '',
		tokenIDDisplay: '',
		tokenURIDisplay: '',
		imageURIDisplay: '',
		notice_board_Array: [],
		state_img_url: '',
		_plus: '',
		_minus: '',
		_points: 0,
		_db_points: '',
		_walletList: '',
		_text: '',
		_successMessage: '',
		_walletExist: '',
		_alreadySubmitted: false,
		points_table_Array: [],
		_votedAddresses: [],
		_eligible: 0,
		_max_per_wallet: '',
		_cost: '',
		_tokenOfOwnerByIndex: [],
		_lastTokenMinted: '',
		_initialScreen: 0,
		_costETH: '',
		myNFTWalletETH: '',
		_max_per_walletETH: '',
		totalSupplyETH: '',
		_ownerETH: '',
		_myTokenIDs: [],
		_selectedNFTID: '',
		walletAddressETH: '',
		_polygonActivate: 0,
		_ethActive: 1,
		_day: '',
		_year: '',
		_date: '',
		_hours: '',
		_minutes: '',
		_seconds: '',
		_dateTime: '',
		_clickedWalletBtn: 0,
		_publicMintMsg: 'Public Mint',
		_lastMintedNFTID: '',
		_timeGap: '',
		_mintedTime: [],
		_myTokenIDsWithTime: [],
		_timeGap: 0,
		_NFTID: '',
		_photoUploaded: 0,
		isSubmitting: false,
		yourName: "",
		donation: "",
		image: null,
		textArea: "",
		agreeBtn: "",
		description: "",
		_msgMintingMore: '',
		_msgCode: 0,
		_timeMinted: '',
		_differenceT: '',
		_cachedProvider: ''
	}

	async componentDidMount() {

		try {

			NFTID = localStorage?.getItem('SelectedNFTID');
			this.setState({ _NFTID: NFTID });
			await new Promise(resolve => setTimeout(resolve, 250));

			console.log("SelectedNFTID :" + this.state._NFTID);

			activePolygon = localStorage?.getItem('polygonActivate');
			this.setState({ _polygonActivate: activePolygon });


		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		successMessage = 0;
		this.setState({ _successMessage: successMessage });
		console.log("status" + this.state._successMessage);


	}

	wordsCount = async event => {
		event.preventDefault();
		words();
	}

	plus = async event => {
		event.preventDefault();
		points = points + 1;
		this.setState({ _points: points });

		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsup', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert :" + this.state._walletList);
		});
		console.log("Data Inserted");



	}

	minus = async event => {
		event.preventDefault();
		points = points - 1;
		this.setState({ _points: points });

		console.log("Writer" + writersWallet);
		console.log("WriterState" + writersWallet);


		Axios.post('https://alpha-bunny-nft.herokuapp.com/pointsdown', {

			list_wallet: writersWallet

		}).then(() => {
			alert("Successful Insert");
		});
		console.log("Data Inserted");
	}

	walletConnect2 = async event => {
		event.preventDefault();

		clickedWalletBtn = 1;

		this.setState({ _clickedWalletBtn: clickedWalletBtn });

		const providerOptions = {

			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 97, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 97, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {
					this.setState({_cachedProvider:web3Modal.cachedProvider});

					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {
						console.log("Function - walletConnect : " + web3Modal.cachedProvider);
						const chainId = 97;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/
											},
										],
									});
								}
							}
						}

						try {

							let previousWalletAddress = localStorage?.getItem('walletAddress');

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);


							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[this.state._myTokenIDs[y]] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}
							await new Promise(resolve => setTimeout(resolve, 250));

							timeGap = Date().now * 1 - mintedTime[this.state._selectedNFTID] * 1;
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);
						}
					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						const chainId = 97;


						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

											},
										],
									});
								}
							}
						}

						try {

							let previousWalletAddress = localStorage?.getItem('walletAddress');

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);


							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[y] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}

							timeGap = Date().now - mintedTime[this.state._selectedNFTID];
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "walletconnect") {

						const chainId = 97;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							for (let y = 0; y < this.state.myNFTWallet; y++) {
								tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

							}

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);


							for (let y = 0; y < this.state._myTokenIDs.length; y++) {

								mintedTime[y] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}

							timeGap = Date().now - mintedTime[this.state._selectedNFTID];
							console.log("timeGap : " + timeGap);

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	walletConnect = async event => {
		event.preventDefault();
		clickedWalletBtn = 1;

		this.setState({ _clickedWalletBtn: clickedWalletBtn });
		const providerOptions = {
			metamask: {
				// Options for Metamask provider
				chainId: 97, // Connect to Ethereum mainnet
			  },
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 97, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 97, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");

		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 97;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],


											/*chainName: 'Smart ChainChain',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],*/
										},
									],
								});
							}
						}
					}


					try {


						try {

							localStorage.setItem('SelectedNFTID', '86');
							await new Promise(resolve => setTimeout(resolve, 3000));
			
							NFTID = localStorage?.getItem('SelectedNFTID');
							await new Promise(resolve => setTimeout(resolve, 1000));
			
							this.setState({ _NFTID: NFTID });
			
			
						} catch (err) {
			
							console.log(err);
			
						}

						timeMinted = await contract.methods.mintedTime(NFTID).call();
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _timeMinted: timeMinted });
						differenceT = (Date.now() * 1) - (timeMinted * 1000);
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _differenceT: differenceT });

						console.log("timeMinted : " + timeMinted);
						console.log("differenceT : " + differenceT);

						if (differenceT < 86400000) {

							console.log("Status : Already Minted");
							msgCode = 1;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);

						} else {

							console.log("Status : Not Minted");
							msgCode = 0;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);
						}

						let previousWalletAddress = localStorage?.getItem('walletAddress');

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);

						for (let y = 0; y < this.state.myNFTWallet; y++) {
							tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
							this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

						}

						owner = await contract.methods.owner().call();
						this.setState({ _owner: owner });
						console.log("Owner" + owner);

						/*for (let y = 0; y < this.state._myTokenIDs.length; y++) {

							mintedTime[this.state._myTokenIDs[y]] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
							this.setState({ _mintedTime: mintedTime });
							console.log("mintedTime" + mintedTime);
						}*/
						await new Promise(resolve => setTimeout(resolve, 250));

						//timeGap = Date().now * 1 - mintedTime[this.state._selectedNFTID] * 1;
						//console.log("timeGap : " + timeGap);

					} catch (err) {
						console.log("err: " + err);
					}

				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 97;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

											/*chainName: 'Smart ChainChain',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],*/
										},
									],
								});
							}
						}
					}

					try {

						try {

							localStorage.setItem('SelectedNFTID', '86');
							await new Promise(resolve => setTimeout(resolve, 3000));
			
							NFTID = localStorage?.getItem('SelectedNFTID');
							await new Promise(resolve => setTimeout(resolve, 1000));
			
							this.setState({ _NFTID: NFTID });
			
			
						} catch (err) {
			
							console.log(err);
			
						}

						timeMinted = await contract.methods.mintedTime(NFTID).call();
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _timeMinted: timeMinted });
						differenceT = (Date.now() * 1) - (timeMinted * 1000);
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _differenceT: differenceT });

						console.log("timeMinted : " + timeMinted);
						console.log("differenceT : " + differenceT);

						if (differenceT < 86400000) {

							console.log("Status : Already Minted");
							msgCode = 1;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);

						} else {

							console.log("Status : Not Minted");
							msgCode = 0;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);
						}

						let previousWalletAddress = localStorage?.getItem('walletAddress');

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);

						for (let y = 0; y < this.state.myNFTWallet; y++) {
							tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
							this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

						}

						owner = await contract.methods.owner().call();
						this.setState({ _owner: owner });
						console.log("Owner" + owner);

						/*for (let y = 0; y < this.state._myTokenIDs.length; y++) {

							mintedTime[this.state._myTokenIDs[y]] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
							this.setState({ _mintedTime: mintedTime });
							console.log("mintedTime" + mintedTime);
						}*/
						await new Promise(resolve => setTimeout(resolve, 250));

						//	timeGap = Date().now * 1 - mintedTime[this.state._selectedNFTID] * 1;
						//	console.log("timeGap : " + timeGap);

					} catch (err) {
						console.log("err: " + err);
					}


				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					const chainId = 97;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/
											chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],

											/*chainName: 'Smart ChainChain',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://bsc-dataseed.binance.org/'],*/
										},
									],
								});
							}
						}
					}

					try {

						try {

							localStorage.setItem('SelectedNFTID', '86');
							await new Promise(resolve => setTimeout(resolve, 3000));
			
							NFTID = localStorage?.getItem('SelectedNFTID');
							await new Promise(resolve => setTimeout(resolve, 1000));
			
							this.setState({ _NFTID: NFTID });
			
			
						} catch (err) {
			
							console.log(err);
			
						}

						timeMinted = await contract.methods.mintedTime(NFTID).call();
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _timeMinted: timeMinted });
						differenceT = (Date.now() * 1) - (timeMinted * 1000);
						await new Promise(resolve => setTimeout(resolve, 1000));

						this.setState({ _differenceT: differenceT });

						console.log("timeMinted : " + timeMinted);
						console.log("differenceT : " + differenceT);

						if (differenceT < 86400000) {

							console.log("Status : Already Minted");
							msgCode = 1;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);

						} else {

							console.log("Status : Not Minted");
							msgCode = 0;
							this.setState({ _msgCode: msgCode });
							//this.setState({_msgMintingMore : msgMintingMore});
							console.log("_msgCode :" + this.state._msgCode);
						}

						let previousWalletAddress = localStorage?.getItem('walletAddress');

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						cost = await contract.methods.publicSaleCost().call();
						this.setState({ _cost: cost });
						console.log("cost :" + cost);

						for (let y = 0; y < this.state.myNFTWallet; y++) {
							tokenOfOwnerByIndex[y] = await contract.methods.tokenOfOwnerByIndex(account, y).call();
							this.setState({ _tokenOfOwnerByIndex: tokenOfOwnerByIndex })

						}

						owner = await contract.methods.owner().call();
						this.setState({ _owner: owner });
						console.log("Owner" + owner);

						/*	for (let y = 0; y < this.state._myTokenIDs.length; y++) {
	
								mintedTime[this.state._myTokenIDs[y]] = await contract.methods.mintedTime(this.state._myTokenIDs[y]).call();
								this.setState({ _mintedTime: mintedTime });
								console.log("mintedTime" + mintedTime);
							}*/
						await new Promise(resolve => setTimeout(resolve, 250));

						//	timeGap = Date().now * 1 - mintedTime[this.state._selectedNFTID] * 1;
						//	console.log("timeGap : " + timeGap);

					} catch (err) {
						console.log("err: " + err);
					}

				}


				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	async createDonationRequest(ID) {
		await new Promise(resolve => setTimeout(resolve, 250));

		selectedNFTID = ID;
		console.log("ID:" + ID);

		this.setState({ _selectedNFTID: ID });
		localStorage.setItem('poetryTokenSelected', ID);
		console.log("selectedNFTID : " + this.state._selectedNFTID);

		localStorage.setItem('SelectedNFTID', ID);
		localStorage.setItem('walletAddress', account);
		localStorage.setItem('polygonActivate', 1);

		initialScreen = 0;
		this.setState({ _initialScreen: initialScreen });
		console.log("initialScreen : " + this.state._initialScreen);

		await new Promise(resolve => setTimeout(resolve, 250));

		polygonActivate = 1;
		this.setState({ _polygonActivate: polygonActivate });
		console.log("polygonActive: " + polygonActivate);

		ethActive = 0;
		this.setState({ _ethActive: ethActive });
		console.log("ethActive: " + ethActive);

		date_ob = new Date();
		day = ("0" + date_ob.getDate()).slice(-2);

		month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
		year = date_ob.getFullYear();

		date = year + "-" + month + "-" + day;
		console.log(date);

		hours = date_ob.getHours();
		minutes = date_ob.getMinutes();
		seconds = date_ob.getSeconds();

		this.setState({ _day: day, _year: year, _date: date, _hours: hours, _minutes: minutes, _seconds: seconds });


		await new Promise(resolve => setTimeout(resolve, 250));

		/*	localStorage.setItem('poetryTokenSelected', ID);
			localStorage.removeItem('poetryTokenSelected',ID);
	
			console.log("poetryTokenSelecteda-3:" + localStorage?.getItem('poetryTokenSelected'));
	*/

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	walletConnectEthereum = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddressETH: account });

			contractETH = new web3.eth.Contract(ABIETH, addressETH);
			console.log("contract :" + contractETH);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {



						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						const chainId = 1;

						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "wallconnect") {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://mainnet.infura.io/v3/'],
											},
										],
									});
								}
							}
						}


						try {

							totalSupplyNFTETH = await contractETH.methods.totalSupply().call();
							this.setState({ totalSupplyETH: totalSupplyNFTETH });
							console.log("Total SupplyETH:" + totalSupplyNFTETH);

							/*max_per_walletETH = await contractETH.methods.max_per_wallet().call();
							this.setState({ _max_per_walletETH: max_per_walletETH });
							console.log("max_per_walletETH:" + max_per_walletETH);*/

							publicSaleETH = await contractETH.methods.balanceOf(account).call();
							this.setState({ myNFTWalletETH: publicSaleETH });

							/*costETH = await contractETH.methods.publicSaleCost().call();
							this.setState({ _costETH: costETH });
							console.log("costETH :" + costETH);*/

							ownerETH = await contractETH.methods.owner().call();
							this.setState({ _ownerETH: ownerETH });
							console.log("OwnerETH" + ownerETH);

							for (let y = 0; y < this.state.myNFTWalletETH; y++) {
								myTokenIDs[y] = await contractETH.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}


			initialScreen = 1;
			this.setState({ _initialScreen: initialScreen });
			console.log("initialScreen : " + initialScreen);



		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();



	}

	closeWindow = async event => {
		event.preventDefault();

		clickedWalletBtn = 0;
		this.setState({ _clickedWalletBtn: clickedWalletBtn })
		console.log("clickedWalletBtn" + this.state._clickedWalletBtn);

	}

	closeWindowNew = async event => {
		event.preventDefault();

		clickedWalletBtn = 0;
		this.setState({ _clickedWalletBtn: clickedWalletBtn })
		console.log("clickedWalletBtn" + this.state._clickedWalletBtn);

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		web3Modal.clearCachedProvider();
		window.location.reload();
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);


		try {


			if (this.state._cachedProvider != "walletconnect" && this.state._cachedProvider != "coinbasewallet") {


			}

			if ((this.state._differenceT * 1) < 86400000) {

				let msgMintingMore = "Already Minted Usig the Same NFT within the past 24 hours";
				msgCode = 1;
				this.setState({ _msgCode: msgCode });
				//this.setState({_msgMintingMore : msgMintingMore});
				console.log("_msgCode :" + this.state._msgCode);

			} else {

				try {


					//NFTID = localStorage?.getItem('SelectedNFTID');
					//this.setState({ _NFTID: NFTID });
					console.log("Mint Amount :" + this.state.mintAmount);
					console.log("_msgCode :" + this.state._msgCode);
					this.setState({ statusError: false, statusLoading: true });
					await new Promise(resolve => setTimeout(resolve, 3000));

					await contract.methods.mint('86').send({
						gasLimit: 385000,
						from: account,
						value: '0'
					});
					this.setState({ statusLoading: false, success: true });


				} catch (err) {

					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}


			}

		} catch (err) {

			console.log(err);

		}

		await new Promise(resolve => setTimeout(resolve, 750));



	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="headerPicAndTitle">
						<div class="nb">Tree Poetry NFT Creator</div>
						<div class="connect2">
							<div class="connect2-2">

								<div class="steps">Step 1</div>

								{this.state.walletAddressETH === '' ?
									(<form onSubmit={this.walletConnectEthereum}>

										{this.state._ethActive === 1 ?
											(<button class="wallet2">Choose Poet</button>) :
											(<button class="wallet2Dis" disabled>Choose Poet</button>)}


									</form>) : (<form onSubmit={this.walletDisconnect}>

										{this.state._ethActive === 1 ?

											(<button class="wallet2" >
												{this.state.walletAddressETH.slice(0, 3) + "..." + this.state.walletAddressETH.slice(39, 42)}</button>)
											: (<button class="wallet2Dis" disabled>
												{this.state.walletAddressETH.slice(0, 3) + "..." + this.state.walletAddressETH.slice(39, 42)}</button>)}
									</form>)}
							</div>
						</div>
					</div>
					<form class="form" action="https://poetree-nft.herokuapp.com/nftCreate" method="post" enctype="multipart/form-data">

						<div class="step2Con">
							<div class="step2">Step 2</div>
							<div class="picAndCon">
								<div class="imageBar">
									<img id="imgPreview" src={pic} alt="Preview Here" />

									{loading == 1 ? (
										<div class="upload">
											<div id="block_container">
												<div class="fileBtn">
													<input class="choosebtn" type="file" name="file" accept="image/png"
														id="photo" required="true" onChange={this.handleChange} />
												</div>

											</div>
										</div>
									) : (
										<div >
											<div id="block_container">
												<div class="fileBtn">
													<input class="choosebtn" type="file" name="file" accept="image/png"
														id="photo" required="true" onChange={this.handleChange} />
												</div>

											</div>

										</div>

									)}

								</div>


								<div class="contentBar">
									<div class="contentH">Metadata</div>

									<p></p>

									{this.state._polygonActivate !== 0 ?
										(<div>
											<div class="textField">
												Creator: Tree Poet #{this.state._NFTID}
											</div>

											<p></p>

										</div>) : null}

									<p></p>

									<div class="textField">
										<div><input hidden type="text" class="comment" value={this.state.walletAddress} name="creator" placeholder="" /></div>
										<div><input hidden type="text" class="comment" value="BNB" name="blockchain" placeholder="" /></div>

									</div>

									<p></p>

									<div class="textField">
										<div></div>
										<div >
											<input hidden type="text" class="comment" value={totalSupplyNFT} name="tokenID" placeholder="" />
											<input hidden type="text" class="comment" value={this.state._NFTID} name="creatorTokenID" placeholder="" />
										</div>
									</div>
									<p></p>

									<div class="textField">
										Charity / Nonprofit:
										<div><input type="text" required class="comment" name="yourName" placeholder="" /></div>
									</div>

									<p></p>
									<div class="textField">
										Donation Request US$:
										<div><input type="number" required class="comment" name="donation" placeholder="" /></div>
									</div>
								</div>
							</div>

							<div class="descriptionMain">
								<div class="description">Description</div>

								<textarea class="descriptionCon" name="textArea">
									Tree Poets NFTs provide proof of intellectual property ownership when creating collectibles and assigning copy right ownership to owner of the collectible and allow transfer to be granted from creator to future owner. If collectible is gifted to fulfill successful donation request then social token rewards are sent to wallet of Tree Poet when collectible was created. The social tokens have no monetary value but are used to obtain the free collectible gifts that are unsuccessful in collecting charitable donations. Collectible gifts can be created from any featured poetry and image of creator or from public domain so there are no transferred copyright as selected within dapp when creating collectible. Collectible gifts created to transfer ownership require same image to be uploaded at TreePoetry.org/collectiblegifts form with contact info and signature so transfer can be a legal contract. If proof of ownership on token or social tokens are not desired then image can be uploaded at TreePoetry.org/collectiblegifts to create a non tokenized collectible gift for your donation request. All collectible transfers and social token rewards are issued 30 days after donation requests are successful so credit card chargebacks can not be made to reclaim donations since charities do not give refunds and chargebacks cannot be made after 30 days. To create a tokenized collectible gift, click the Tree Poetry logo above, connect your wallet and select your Tree Poet in your wallet
								</textarea>

								<div class="agreeBtn">
									<input class="tic" type="radio" required id="agreeBtn" name="agreeBtn" value="None" />
									<div class="agree">I certify I DO NOT transfer any content copyright.</div>

								</div>

								<p></p>

								<div class="agreeBtn2">
									<div class="agreeBtn4">
										<div class="agree">I certify I am original creator and DO transfer copyright ownership of</div>
									</div>

									<div class="agreeBtn3">
										<div class="agreeBtn">
											<input class="tic" required type="radio" id="agreeBtn" name="agreeBtn" value="Poetry" />
											<div class="agree">Poetry</div>
										</div>
										<div class="agreeBtn">
											<input class="tic" required type="radio" id="agreeBtn" name="agreeBtn" value="Illustration" />
											<div class="agree">Illustration</div>
										</div>
										<div class="agreeBtn">
											<input class="tic" required type="radio" id="agreeBtn" name="agreeBtn" value="Poetry & Art" />
											<div class="agree">Poetry & Illustration</div>
										</div>
									</div>

								</div>

								<div class="con2Main">
									<div class="con2">
										NFT will be held in the Tree Poetry Vault until transferred for successful donation but if found to be in violation
										of copyright or terms then the NFT will be burned.

									</div>
								</div>
							</div>
						</div>

						<div class="step3Con">
							<div class="step3">Step 3</div>
							{this.state.walletAddress === '' ?
								(<div>
									{this.state._polygonActivate === 0 ?
										(<button class="wallet2Dis" disabled>Mint NFT</button>) :
										(<button class="wallet2" onClick={this.walletConnect}>Mint NFT</button>)}

								</div>) : (<button onClick={this.walletDisconnect} class="wallet2" >
									{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button>)}
						</div>

						<div class="step4ConMain">
							<div class="step4Con">
								<div class="step4">Step 4</div>
								{this.state.walletAddress === '' ?
									(<button class="approveDis" disabled>Submit</button>) :
									(<button class="approve" type="submit">Submit</button>)
								}
							</div>
						</div>
					</form>
					<p></p>
					<p></p>
				</div>

				{this.state._initialScreen === 1 ?
					(<div class="formMain1" >
						<div class="formMain">

							<div class="formCon">Choose Tree Poet NFT</div>
							<div class="formCon2">Tree Poet holder receives claim priority and donation rewards of NFT mint.</div>
							<div class="tokenIds" >

								{this.state._myTokenIDs.map((element) => {

									var ID = element * 1;

									if (element == 0) {
										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";
										console.log("url :" + url);


									} else {

										var url = "https://treepoets.com/images/ETH/" + ID + ".jpg";

									}



									return (
										<div class="ownNFT">
											<div class="flip-card">
												<div class="flip-card-inner">
													<div class="flip-card-front">


														<img class="images_show" src={url} /></div>

													<div class="flip-card-back">
														<div class="elementName">{element}</div>
														<button class="stake" onClick={() => this.createDonationRequest(element)}>SELECT</button>
													</div>

												</div>
											</div></div>);



								})}


							</div>

							<button class="mintMore" onClick={website}>Mint Tree Poets NFTs</button>

						</div>

					</div>) : null
				}


				{this.state.walletAddress !== "" && this.state._clickedWalletBtn > 0 ?
					(<div class="formMain1" >
						<div class="formMain2">

							<div class="formCon">Mint Section</div>
							<p class="formCon2">Mint an NFT to claim rights and create donation gifts that earn rewards.</p>

							{this.state._msgCode * 1 > 0 ?
								(<form><button disabled class="mintBtnDis">{this.state._msgCode} Already Minted within the 24 Hours</button></form>) :
								(<form onSubmit={this.onSubmit2}><button class="mintBtn">{this.state._msgCode} MINT HERE</button></form>)

							}

							{this.state.statusError ? (
								<div class="errorMessage">
									<div >Sorry, something went wrong please try again later</div>
								</div>)
								: null}

							{this.state.statusLoading ? (
								<div class="loadingContainer">
									<div>
										<div class="loadingText">Minting Digital Collectible Donation Gift</div>
									</div>
								</div>)
								: null}

							{this.state.success ? (

								<div>
									<div class="arrowAndCon">
										<div class="successfully">MINTING SUCCESSFUL!</div>
										<img class="arrow" onClick={this.closeWindow} src={arrow} />

									</div>
									<div class="instructions">Now you can go and fill your information and submit</div>
									<div class="instructions">{this.state._msgMintingMore}</div>
								</div>
							)
								: null}
						</div>
						<button onClick={this.closeWindowNew} class="closeNoteNew">✖</button>

					</div>) : null
				}


			</div>)
	}

}

export default App;